import React from "react"
import Layout from "../../components/layout/layout"
import Content from "../../components/content/content"
import Header from "../../components/header/header"
import { useUserOrgs } from "../../hooks/use-user"
import { Card, CardHeader, CardContent } from "@material-ui/core"
import EchartsMap from "../../components/metrics/metrics-echarts/echarts-map"
import { Loading } from "../../components/loading"

const GitlabPage = () => {
  const { loading } = useUserOrgs()

  if (loading) {
    return <Loading />
  }

  return (
    <Layout pageTitle="Git Dashboard">
      <Header contentPage={true} className="contentPage">
        <h1 className="display-4 font-weight-bold">Git Dashboard</h1>
      </Header>
      <Content>
        <Card>
          <CardHeader title="Git Statistics"></CardHeader>
          <CardContent>
            <EchartsMap></EchartsMap>
          </CardContent>
        </Card>
      </Content>
    </Layout>
  )
}

export default GitlabPage
